<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper title="Family Content" @onClose="close" />
			</template>
			<template #default>
				<div class="content">
					<CRow>
						<CCol md="6">
							<CInput
								v-model.trim="formData.titleEn"
								label="Title (EN)"
							/>
						</CCol>
						<CCol md="6">
							<CInput
								v-model.trim="formData.titleTh"
								label="Title (TH)"
							/>
						</CCol>
					</CRow>

					<!-- Status -->
					<CRow class="mb-5">
						<CCol xl="12" class="d-flex align-items-center">
							<label class="toggle-label" for="display-status">Lowest price status</label>
							<CSwitch
								id="display-status"
								:checked="formData.isLowestPriceVisible"
								variant="3d"
								size="sm"
								class="switch-custom toggle-status"
								color="success"
								@update:checked="handleUpdateLowestPriceStatus"
							/>
							<span>{{ statusText }}</span>
						</CCol>
					</CRow>

					<hr>

					<div class="d-flex justify-content-between">
						<div><h5>Badge Labels</h5></div>
						<div>
							<CButton
								color="secondary"
								class="btn btn-secondary"
								@click="openAddForceBadgeLabelModal"
							>
								Add force badge label
							</CButton>
						</div>
					</div>
					<div class="typo-body-2 color-black-45 pt-3">Force badge label will display on top category title Product Families on LOB Pages</div>
					<div>
						<BadgeLabelList
							v-if="badgeList.length"
							:list="badgeList"
							is-show-icon-trash
							is-disable-check
							@remove="handleRemoveBadgeLabel"
						/>
					</div>
				</div>
			</template>
			<template #footer>
				<BaseModalFooter
					@onCancel="close"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
		<ModalAddForceBadgeLabel
			ref="add-force-badge-label-modal"
			@onClose="handleOnCloseAddBadgeLabelModal"
			@onSubmit="handleSubmitAddBageLabelModal"
		/>
	</div>
</template>

<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import ModalAddForceBadgeLabel from '@/components/ModalAddForceBadgeLabel.vue';
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import BadgeLabelList from '@/components/BadgeLabelList.vue';
import { STATUS as mappingStatus } from '../mapping/banner';

export default {
	name: 'ModalBadgeLabel',
	components: {
		ModalHeaderWrapper,
		ModalAddForceBadgeLabel,
		BaseModalFooter,
		BadgeLabelList,
	},
	data() {
		return {
			isShow: false,
			badgeList: [],
			formData: {
				titleTh: '',
				titleEn: '',
				isLowestPriceVisible: true,
			},
		};
	},
	computed: {
		statusText() {
			const text = mappingStatus[this.formData.isLowestPriceVisible] || '';
			return this.$t(text);
		},
	},
	methods: {
		open(data) {
			this.badgeList = data.badgeLabels;
			this.formData.titleEn = data.titleEn;
			this.formData.titleTh = data.titleTh;
			this.formData.isLowestPriceVisible = data.isLowestPriceVisible;
			this.isShow = true;
		},
		close() {
			this.isShow = false;
		},
		handleUpdateLowestPriceStatus(value) {
			this.formData.isLowestPriceVisible = value;
		},
		handleSubmit() {
			this.$emit('onSubmit', {
				titleEn: this.formData.titleEn,
				titleTh: this.formData.titleTh,
				badgeLabels: this.badgeList,
				isLowestPriceVisible: this.formData.isLowestPriceVisible,
			});
			this.close();
		},
		handleCancel() {
			this.$emit('onCancel');
			this.close();
		},
		handleRemoveBadgeLabel(data) {
			this.badgeList = data;
		},
		handleSubmitAddBageLabelModal(data) {
			this.badgeList = data;
			this.isShow = true;
		},
		handleOnCloseAddBadgeLabelModal() {
			this.isShow = true;
		},
		openAddForceBadgeLabelModal() {
			this.$refs['add-force-badge-label-modal'].open(null, this.badgeList);
			this.close();
		},
	},
};
</script>
<style lang="scss" scoped>
::v-deep .modal-dialog {
	max-width: rem(700);
}

.content {
	min-height: 100px;
}

.toggle {
	// .toggle-label
	&-label {
		margin: 0 rem(24) 0 0;
	}

	// .toggle-status
	&-status {
		margin-right: rem(12);
	}
}
</style>
