<template>
	<CRow class="mb-4">
		<CCol>
			<CRow class="mb-3">
				<CCol>
					<h6 data-test-id="title">
						{{ title }}
					</h6>
					<div
						class="typo-body-2 color-black-45"
						data-test-id="description"
					>
						{{ description }}
					</div>
				</CCol>
				<CCol
					lg="6"
					class="text-right"
				>
					<div class="btn-action-wrapper">
						<CButton
							color="secondary"
							data-test-id="add-categories"
							@click="handleOpenCategoris"
						>
							{{ categoryList.length ? 'Edit' : 'Add' }} category
						</CButton>
						<CButton
							color="secondary"
							class="btn btn-secondary"
							@click="handleOpenSkus"
						>
							{{ productList.length ? 'Edit' : 'Add' }} products
						</CButton>
					</div>
				</CCol>
			</CRow>
			<CRow class="mt-4">
				<CCol
					v-if="items.length"
					lg="12"
				>
					<CRow>
						<CCol>
							<span class="label">SKUs/Categories</span>
						</CCol>
					</CRow>
					<CRow>
						<CCol class="d-flex px-4 text-center">
							<CCol md="2" class="label">
								Default
							</CCol>
							<CCol md="2" class="label">
								Custom
							</CCol>
						</CCol>
					</CRow>
					<ul class="list-unstyled p-0">
						<draggable
							class="border rounded-sm border-secondary"
							data-test-id="product-group-sku"
							tag="li"
							:value="items"
							:disabled="!isDraggable"
							@input="handleUpdateOrder"
						>
							<div
								v-for="product in items"
								:key="`${product.id}${product.type}`"
								class="typo-body-2 d-flex px-3 text-decoration-none"
								:class="{'draggable':isDraggable}"
							>
								<div class="draggable-item product-item w-100">
									<ProductFamilyAttachItem
										:preview-image="product.customImage"
										:default-image="product.thumbnail"
										:name="product.name"
										:sku="product.sku"
										:type="product.type"
										:is-lowest-price-visible="product.isLowestPriceVisible"
										@clickAddBadgeLabel="showModalAddBadgeLabel(product)"
										@onInputImage="(image) => handleInputImage(image, product.id)"
									/>
								</div>
							</div>
						</draggable>
					</ul>
				</CCol>
				<CCol v-else-if="!isCustom">
					<BaseNoItemBanner
						text="No SKUs/Categories."
						class="mb-3 "
					/>
				</CCol>
				<!-- Custom Empty -->
				<CCol v-else>
					<div
						class="list-empty rounded-sm"
					>
						<a class="duplicate-button d-flex" @click.prevent="handleDuplicate">
							<img src="/assets/images/copy.svg" alt="mobile banner">
							<span class="button-message ml-2 border-bottom">Click here to duplicate</span>
						</a>
						<div class="mt-2">
							Duplicate SKUs/Categories from the Default page.
						</div>
					</div>
				</CCol>
			</CRow>
		</CCol>

		<!-- Modal Section -->
		<ModalSKU
			ref="modal-sku"
			:sku-list="productList"
			title="Add products"
			:is-multiple="isMultiple"
			@onConfirm="(products) => handleUpdateProductAndCategoryList(products, PRODUCT_FAMILY_ITEM_TYPES.PRODUCT)"
		/>
		<ModalCategory
			ref="modal-category"
			title="Select categories"
			:selected-ids="categoryList"
			:enable-select-all="false"
			@onSubmit="(category) => handleUpdateProductAndCategoryList(category, PRODUCT_FAMILY_ITEM_TYPES.CATEGORY)"
		/>
		<ModalBadgeLabel ref="badge-label-modal" @onSubmit="handleSaveBadgeLabel" />
	</CRow>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import draggable from 'vuedraggable';
import ModalSKU from '@/components/ModalSKU.vue';
import ModalCategory from '@/components/ModalCategory.vue';
import ModalBadgeLabel from '@/components/ModalBadgeLabel.vue';
import ProductFamilyAttachItem from '@/components/ProductFamilyAttachItem.vue';
import { IMAGE_PLACEHOLDER_URL } from '../enums/general';
import { PRODUCT_FAMILY_ITEM_TYPES } from '../enums/productFamilies';

export default {
	name: 'ProductFamilyAttaches',
	components: {
		draggable,
		ModalSKU,
		ProductFamilyAttachItem,
		ModalCategory,
		ModalBadgeLabel,
	},
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
		isDraggable: {
			type: Boolean,
			default: false,
		},
		isMultiple: {
			type: Boolean,
			default: false,
		},
		isCustom: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			PRODUCT_FAMILY_ITEM_TYPES,
			IMAGE_PLACEHOLDER_URL,
			dummyList: [],
			productSeleced: null,
		};
	},
	computed: {
		...mapGetters({
			getSelectedCategories: 'categorySelector/getSelectedCategories',
		}),
		productList() {
			return this.dummyList.filter((item) => item.type === PRODUCT_FAMILY_ITEM_TYPES.PRODUCT) || [];
		},
		categoryList() {
			return this.dummyList.filter((item) => item.type === PRODUCT_FAMILY_ITEM_TYPES.CATEGORY).map((item) => item.id) || [];
		},
	},
	watch: {
		items: {
			immediate: true,
			handler(list) {
				this.dummyList = list;
			},
		},
	},
	methods: {
		...mapActions({
			preSelectCategories: 'categorySelector/preSelectCategories',
		}),
		handleUpdateOrder(sortedList) {
			if (!this.isDraggable) {
				return;
			}
			this.dummyList = sortedList;

			this.$emit('onDragged', this.dummyList);
		},
		handleUpdateProductAndCategoryList(list, type) {
			// filter coming type out of recent array
			const filteredItem = this.dummyList.filter((item) => item.type !== type);

			// merge new sku/category with filteredItem
			const mergeNewItem = [...filteredItem, ...list.map((item) => { return { ...item, type }; })];

			// transform pathName TYPE=CATEGORY for  display Category Level name
			const transformedPathName = mergeNewItem.map((newItem) => {
				if (type === PRODUCT_FAMILY_ITEM_TYPES.CATEGORY) {
					return {
						...newItem,
						pathName: newItem.pathName?.join('/'),
					};
				}
				return newItem;
			});

			this.$emit('update:items', transformedPathName);
		},
		handleInputImage(image, id) {
			const index = this.items.findIndex((item) => item.id === id);
			if (index > -1) {
				this.items[index] = {
					...this.items[index],
					customImage: image,
				};
			}
		},
		showModalAddBadgeLabel(product) {
			this.productSeleced = product;
			const badgeLabels = product.badgeLabels ?? [];
			this.$refs['badge-label-modal'].open({
				badgeLabels,
				titleTh: product.titleTh,
				titleEn: product.titleEn,
				isLowestPriceVisible: product.isLowestPriceVisible,
			});
		},
		handleSaveBadgeLabel(data) {
			const badges = data.badgeLabels;
			const list = this.dummyList;
			list.forEach((item) => {
				if (item.id === this.productSeleced.id) {
					item.badgeLabels = badges;
					item.titleTh = data.titleTh;
					item.titleEn = data.titleEn;
					item.isLowestPriceVisible = data.isLowestPriceVisible;
				}
			});
			this.dummyList = list;
			this.$emit('update:items', this.dummyList);
		},
		handleDuplicate() {
			this.$emit('duplicate');
		},
		handleOpenCategoris() {
			const categories = this.items.filter((e) => {
				return e.type === PRODUCT_FAMILY_ITEM_TYPES.CATEGORY;
			});
			const selectedIds = categories.map((e) => e.id);
			this.preSelectCategories(selectedIds);
			this.$refs['modal-category'].open();
		},
		handleOpenSkus() {
			const modalSku = this.$refs['modal-sku'];
			modalSku.handleReset();
			modalSku.open();
		},
	},
};
</script>

<style lang="scss" scoped>
	.thumbnail {
		width: rem(40);
		height: rem(40);
		object-fit: contain;
	}

	.description {
		color: $color-black-45;
	}

	.badge-primary-product {
		background-color: $color-gray-400;
		color: $color-black-70;
	}

	.draggable {
		cursor: move;

		&:hover {
			.draggable-item {
				background-color: $color-gray-100;
			}
		}

		// .draggable-item
		&-item {
			display: flex;
			align-items: center;
			height: rem(80);

			> .row {
				margin-bottom: 0 !important;
			}
		}
	}

	.btn-action-wrapper {
		display: flex;
		gap: rem(8);
		margin-top: rem(16);

		@include lg {
			margin-top: 0;
		}
	}

	.product-item {
		> .row {
			margin-bottom: 0;
		}
	}

	.duplicate-button {
		&:hover {
			cursor: pointer;
		}

		.button-message {
			line-height: 1;
		}
	}

	.list-empty {
		height: 140px;
		background-color: $color-gray-100;
		color: $color-black-45;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

</style>
