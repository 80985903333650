<template>
	<CModal
		:show.sync="isShow"
		:close-on-backdrop="false"
		centered
	>
		<template #header-wrapper>
			<ModalHeaderWrapper
				title="Add force badge label"
				@onClose="handleClose"
			/>
		</template>
		<template #default class="p-0">
			<BaseLoading
				v-if="list.isLoading"
				class="loading d-flex align-items-center"
			/>
			<div v-else>
				<BadgeLabelList
					v-if="badgeList.length"
					:list="badgeList"
					@check="handleChecked"
				/>
			</div>
		</template>
		<template #footer>
			<BaseModalFooter
				text-confirm="Confirm"
				@onCancel="handleClose"
				@onConfirm="handleSubmit"
			/>
		</template>
	</CModal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import BadgeLabelList from '@/components/BadgeLabelList.vue';
import {
	PRODUCT_LABEL_TYPES,
} from '../enums/productLabels';

export default {
	name: 'ModalAddForceBadgeLabel',
	components: {
		ModalHeaderWrapper,
		BaseModalFooter,
		BadgeLabelList,
	},
	data() {
		return {
			isShow: false,
			type: PRODUCT_LABEL_TYPES.badge,
			badgeList: [],
			badgesChecked: [],
		};
	},
	computed: {
		...mapState('productLabels', {
			list: 'list',
		}),
		...mapGetters({
			badgeLabels: 'productLabels/getBadgeLabels',
		}),
	},
	methods: {
		...mapActions({
			getProductLabels: 'productLabels/getProductLabels',
		}),
		async open(categoryContentId = '', badgeLabels = []) {
			await this.getProductLabels({ type: this.type, per_page: 'all', content_id: categoryContentId });
			this.isShow = true;
			this.badgesChecked = badgeLabels;
			this.setCheckedBadgeList();
		},
		close() {
			this.isShow = false;
			this.searchTerm = '';
		},
		handleClose() {
			this.$emit('onClose');
			this.close();
		},
		handleSubmit() {
			const itemSelected = this.badgeList.filter((item) => item.isLabelVisible != null);
			this.$emit('onSubmit', itemSelected);
			this.close();
		},
		handleChecked(data) {
			this.badgeList = data;
		},
		setCheckedBadgeList() {
			const checkedlist = this.badgesChecked;
			const badgeslist = this.badgeLabels;
			badgeslist.forEach((badge) => {
				const badgeChecked = checkedlist.find((check) => badge.id === check.id);
				if (badgeChecked) {
					Object.assign(badge, badgeChecked);
				} else {
					badge.isLabelVisible = null;
				}
			});
			this.badgeList = badgeslist;
		},
	},
};
</script>
