<template>
	<div class="px-2">
		<div class="row font-bold">
			<div class="col-7">Badge Label</div>
			<div class="col-2">Display</div>
			<div class="col-2">Not Display</div>
		</div>
		<div
			v-for="(item,index) in badgeList"
			:key="index"
			class="row rounded p-2 mt-3"
			:class="[item.isLabelVisible != null && !isDisableCheck ? 'checked-border' : 'border']"
		>
			<div class="col-7" :style="`color:${item.fontColor}`">
				{{ getBadgeName(item) }}
			</div>
			<div class="col-2 pl-4">
				<CInputCheckbox
					:value="true"
					:checked="item.isLabelVisible == true"
					:disabled="isDisableCheck"
					custom
					@update:checked="handleChecked($event, item, true)"
				/>
			</div>
			<div class="col-2 pl-4">
				<CInputCheckbox
					:value="false"
					:checked="item.isLabelVisible == false"
					:disabled="isDisableCheck"
					custom
					@update:checked="handleChecked($event, item, false)"
				/>
			</div>
			<div class="col-1">
				<a v-if="isShowIconTrash" href="" @click.prevent="handleRemoveItem(item.id)">
					<CIcon
						class="cil-trash"
						name="cil-trash"
					/>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BadgeLabelList',
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		isDisableCheck: {
			type: Boolean,
			default: false,
		},
		isShowIconTrash: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			badgeList: [],
		};
	},
	watch: {
		list(newValue) {
			this.badgeList = newValue;
		},
	},
	created() {
		this.badgeList = this.list;
	},
	methods: {
		handleChecked(isChecked, item, value) {
			if (this.isDisableCheck) { return; }
			item.isLabelVisible = isChecked ? value : null;
			this.$emit('check', this.badgeList);
		},
		getBadgeName(item) {
			return item ? item.name.toUpperCase() : '';
		},
		handleRemoveItem(id) {
			this.badgeList = this.badgeList.filter((badge) => badge.id !== id);
			this.$emit('remove', this.badgeList);
		},
	},
};
</script>
<style lang="scss">
.font-bold {
	font-weight: $font-weight-bold;
}

.cil-trash {
	cursor: pointer;
	color: $color-gray-400;

	&:hover {
		color: $color-gray-800;
	}
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
	opacity: 0.5 !important;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
	background-color: $color-white;
}

.checked-border {
	border: 1px solid #000;
}
</style>
