
<template>
	<div class="mb-4">
		<form
			class="main-wrapper mt-4"
			@submit.prevent="handleSubmit"
		>
			<CRow>
				<CCol>
					<h4 class="mb-4">
						General Info
					</h4>
					<CInput
						v-model="$v.localProductFamilyName.$model"
						:is-valid="!$v.localProductFamilyName.$error && null"
						:invalid-feedback="nameErrors"
						label="Family Name*"
						placeholder="Ipad Family"
						data-test-id="family-name"
					/>
					<CRow>
						<CCol>
							<div class="form-group mt-2">
								<p class="label label-btn-group">
									Product Family status
								</p>
								<BaseButtonGroup
									v-model="localProductFamilyStatus"
									:list="PRODUCT_FAMILY_STATUS_FORM"
									data-test-id="pre-order-status"
									@input="(value) => $emit('update:familyStatus', value)"
								/>
							</div>
						</CCol>
						<CCol>
							<CInput
								v-model="$v.localProductFamilyPriority.$model"
								:is-valid="!$v.localProductFamilyPriority.$error && null"
								invalid-feedback="Required Field"
								label="Priority*"
								placeholder="Ipad Family"
								data-test-id="family-name"
								type="number"
							/>
						</CCol>
					</CRow>
					<hr class="mt-4">
				</CCol>
			</CRow>

			<CRow>
				<CCol>
					<h4 class="mb-4">
						Products / Categories
					</h4>

					<div class="tabs-container-large">
						<div class="tabs-wrapper">
							<CTabs
								:active-tab="currentTabIndex"
								@update:activeTab="handleUpdateTab"
							>
								<CTab
									v-for="(step, index) in PRODUCT_FAMILY_TABS"
									:key="index"
									:title="step.title"
								>
									<ProductFamilyAttaches
										class="mt-4"
										:items="getProductFamilyItems(index)"
										description="These products/categories are will show when set and apply in categories. (recommend products/categories should be more than 4 items)"
										is-draggable
										is-multiple
										:is-custom="index !== 0"
										@duplicate="setDuplicateItems"
										@update:items="(value) => setProductFamilyItems(index, value)"
										@onDragged="(newList) => setProductFamilyItems(index, newList)"
									/>
								</CTab>
							</CTabs>
						</div>

						<CCol v-if="!isDefaultTab" class="mt-4">
							<CRow class="align-items-baseline">
								<CCol md="4">
									<BaseInputDate
										v-model="$v.startDateValue.$model"
										:label="`Start date${canEditStartDateTime ? '*' : ''}`"
										:disabled="!canEditStartDateTime"
										:min-date="minActiveDate"
										:is-valid="!$v.startDateValue.$error"
										:invalid-feedback="$t('global.error.required')"
										@input="resetError"
									>
										<template #append-content>
											<CIcon class="cil-calendar" name="cil-calendar" />
										</template>
									</BaseInputDate>
									<div
										:class="['invalid-feedback pl-2', { '-has-error' : isDateTimePast }]"
									>
										{{ $t('global.errorMessageDateTimePast') }}
									</div>
								</CCol>
								<CCol md="2">
									<BaseInputTime
										v-model="$v.startTimeValue.$model"
										:label="`Start time${canEditStartDateTime ? '*' : ''}`"
										:disabled="!canEditStartDateTime"
										:is-valid="!$v.startTimeValue.$error"
										:invalid-feedback="$t('global.error.required')"
										@input="resetError"
									/>
								</CCol>
							</CRow>
						</CCol>
					</div>
					<hr class="mt-4">
				</CCol>
			</CRow>

			<BaseActionPanelStickyFooter
				:is-edit="isEdit"
				:disabled-confirm="isSubmitting"
				:button-edit-confirm-text="isSubmitting ? 'Saving...' : 'Save changes'"
				:remove-text="isEdit ? 'Remove product family' : null"
				content-class="main-wrapper-large"
				@onConfirm="handleSubmit"
				@onCancel="$router.push({ name: 'ProductFamilyList'})"
				@onRemove="isEdit ? $refs['modal-remove'].open() : null"
			/>
		</form>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteProductFamily.bind(null, productFamilyId)"
			description="Are you sure you want to delete this product family?"
			title="Remove this product family?"
			is-confirm-required
			@onSuccess="$router.push({ name: 'ProductFamilyList'})"
		/>
	</div>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapGetters } from 'vuex';
import ProductFamilyAttaches from '@/components/ProductFamilyAttaches.vue';
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import {
	PRODUCT_FAMILY_STATUS_FORM,
	PRODUCT_FAMILY_STATUS_VALUES,
	PRODUCT_FAMILY_ITEM_TYPES,
	PRODUCT_FAMILY_TABS,
	PRODUCT_FAMILY_TYPES,
} from '../enums/productFamilies';
import { convertDateTimeToUTC, cloneDeep } from '../assets/js/helpers';

export default {
	name: 'ProductFamilyForm',

	components: {
		ProductFamilyAttaches,
	},

	validations: {
		localProductFamilyName: {
			required,
			maxLength: maxLength(255),
		},
		localProductFamilyPriority: {
			required,
			maxLength: maxLength(255),
		},
		localProductFamilyStatus: {
			required,
		},
		startDateValue: {
			required: requiredIf((data) => {
				const customItems = data.localProductFamilyCustomItems;
				return customItems && customItems.length > 0;
			}),
		},
		startTimeValue: {
			required: requiredIf((data) => {
				const customItems = data.localProductFamilyCustomItems;
				return customItems && customItems.length > 0;
			}),
		},
	},

	mixins: [validationMixin],

	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		productFamily: {
			type: Object,
			default: () => {},
		},
		productFamilyId: {
			type: [String, Number],
			default: null,
		},
	},

	data() {
		const { status, name, priority, items } = this.productFamily || {};
		const defaultItems = items?.filter((e) => e.itemType === PRODUCT_FAMILY_TYPES.DEFAULT);
		const customItems = items?.filter((e) => e.itemType === PRODUCT_FAMILY_TYPES.CUSTOM);

		return {
			isSubmitting: false,
			PRODUCT_FAMILY_STATUS_FORM,
			PRODUCT_FAMILY_ITEM_TYPES,
			PRODUCT_FAMILY_TABS,

			currentTabIndex: 0,
			localProductFamilyName: name || '',
			localProductFamilyStatus: status || PRODUCT_FAMILY_STATUS_FORM.find((item) => item.value === PRODUCT_FAMILY_STATUS_VALUES.ACTIVE).value, // Default as 'inactive'
			localProductFamilyPriority: priority || 10, // default priority is 10
			localProductFamilyItems: defaultItems || [],
			localProductFamilyCustomItems: customItems || [],

			minActiveDate: new Date(),
			startDateValue: null,
			startTimeValue: null,
			isDateTimePast: false,
		};
	},

	computed: {
		...mapGetters({
			getCategoryById: 'categorySelector/getCategoryById',
		}),
		nameErrors() {
			if (!this.$v.localProductFamilyName.required) {
				return 'Required Field';
			}

			if (!this.$v.localProductFamilyName.maxLength) {
				return 'Maximum name length is 255';
			}

			return null;
		},
		isDefaultTab() {
			return this.currentTabIndex === 0;
		},
		canEditStartDateTime() {
			return this.localProductFamilyCustomItems.length > 0;
		},
	},

	mounted() {
		if (this.isEdit) {
			const { status, name, priority, items, startDate, startTime } = this.productFamily || {};
			this.localProductFamilyName	= name || '';
			this.localProductFamilyStatus = [PRODUCT_FAMILY_STATUS_VALUES.ACTIVE, PRODUCT_FAMILY_STATUS_VALUES.INACTIVE].includes(Number(status)) ? status : PRODUCT_FAMILY_STATUS_FORM.find((item) => item.value === PRODUCT_FAMILY_STATUS_VALUES.ACTIVE).value; // Default as 'inactive
			this.localProductFamilyPriority = priority;

			const defaultItems = items?.filter((e) => e.itemType === PRODUCT_FAMILY_TYPES.DEFAULT);
			const customItems = items?.filter((e) => e.itemType === PRODUCT_FAMILY_TYPES.CUSTOM);
			this.localProductFamilyItems = defaultItems?.map((item) => {
				return { ...item, ...(item.type === PRODUCT_FAMILY_ITEM_TYPES.CATEGORY ? { pathName: this.getCategoryById(item.id)?.pathName?.join('/') ?? [] } : {}) };
			}) || [];
			this.localProductFamilyCustomItems = customItems?.map((item) => {
				return { ...item, ...(item.type === PRODUCT_FAMILY_ITEM_TYPES.CATEGORY ? { pathName: this.getCategoryById(item.id)?.pathName?.join('/') ?? [] } : {}) };
			}) || [];
			this.startDateValue = startDate;
			this.startTimeValue = startTime;
		}
	},

	methods: {
		...mapActions({
			createProductFamily: 'productFamilies/postProductFamily',
			updateProductFamily: 'productFamilies/updateProductFamily',
			deleteProductFamily: 'productFamilies/deleteProductFamily',
			showToast: 'toast/showToast',
		}),

		handleUpdateTab(tabIndex) {
			this.currentTabIndex = tabIndex;
		},

		getProductFamilyItems(index) {
			if (index === 0) {
				return this.localProductFamilyItems;
			}
			return this.localProductFamilyCustomItems;
		},

		setDuplicateItems() {
			this.localProductFamilyCustomItems = cloneDeep(this.localProductFamilyItems);
		},

		setProductFamilyItems(index, items) {
			if (index === 0) {
				this.localProductFamilyItems = items;
			} else {
				this.localProductFamilyCustomItems = items;
			}
		},
		isAfterNow() {
			if (this.localProductFamilyCustomItems.length <= 0) {
				// case no customItems always true
				return true;
			} else if (this.localProductFamilyCustomItems.length > 0) {
				if (!this.startDateValue) {
					// Display error from validations
					return true;
				}

				if (this.startTimeValue) {
					const startTimeLength = this.startTimeValue.length;
					if (!(startTimeLength === 5)) {
						return true;
					}
				} else {
					return true;
				}
			}

			const now = new Date();
			const startDate = convertDateTimeToUTC(this.startDateValue, this.startTimeValue);
			return dayjs(startDate).isAfter(now);
		},
		resetError() {
			const isAfterNow = this.isAfterNow();
			this.isDateTimePast = !isAfterNow;
		},
		async handleSubmit() {
			this.isSubmitting = true;
			this.$v.$touch();
			const isAfterNow = this.isAfterNow();
			if (this.$v.$invalid || !isAfterNow) {
				this.isSubmitting = false;
				this.isDateTimePast = !isAfterNow;
			} else {
				this.isDateTimePast = false;
				const defaultItems = this.localProductFamilyItems.map((e) => {
					return {
						...e,
						itemType: PRODUCT_FAMILY_TYPES.DEFAULT,
					};
				});
				const customItems = this.localProductFamilyCustomItems.map((e) => {
					return {
						...e,
						itemType: PRODUCT_FAMILY_TYPES.CUSTOM,
					};
				});
				const allItems = defaultItems.concat(customItems);
				const payload = {
					...(this.isEdit ? { id: this.productFamilyId } : {}),
					name: this.localProductFamilyName,
					is_visible: this.localProductFamilyStatus,
					priority: this.localProductFamilyPriority,
					start_at: convertDateTimeToUTC(this.startDateValue, this.startTimeValue),
					items: allItems.map((item) => {
						return {
							type: item.type,
							id: item.id,
							title_en: item.titleEn,
							title_th: item.titleTh,
							item_type: item.itemType,
							is_lowest_price_visible: item.isLowestPriceVisible ?? true,
							media: {
								thumbnail_file_id: item.customImage?.id,
							},
							label_ids: item.badgeLabels?.map((badge) => ({
								label_id: badge.id,
								is_label_visible: badge.isLabelVisible ? 1 : 0,
							})) ?? [],
						};
					}),
				};
				try {
					if (!this.isEdit) {
						await this.createProductFamily(payload);
						this.$router.push({ name: 'ProductFamilyList' });
					} else {
						await this.updateProductFamily({ id: this.productFamilyId, payload });
						this.$router.push({ name: 'ProductFamilyList' });
					}
				} catch (err) {
					this.showToast({
						header: this.$t('global.errorMessageTitleEdit'),
						content: this.$t('global.errorMessage'),
						type: 'danger',
					});
				} finally {
					this.isSubmitting = false;
				}
			}
		},

	},
};
</script>

<style lang="scss" scoped>
	.label-btn-group {
		margin-bottom: rem(10);
	}

	.tabs-wrapper {
		::v-deep & > div > div:first-child {
			margin-top: 0;
			padding-top: 0;
			background-color: transparent;
		}
	}

	.invalid-feedback {
		&.-has-error {
			display: block;
		}
	}
</style>
