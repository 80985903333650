<template>
	<CRow class="mb-3 w-100">
		<CCol class="d-flex">
			<CCol md="2">
				<img
					md="2"
					class="default-image"
					data-test-id="defaultImage"
					:src="defaultImage"
				>
			</CCol>

			<CCol md="2" class="align-self-center">
				<BaseInputImage
					data-test-id="previewImage"
					:thumbnail="showPreviewImage"
					title="Add Image"
					class="mr-4 input-image"
					@onClick="handleClickPreviewImage"
					@onDelete="handleDeletePreviewImage"
				/>
			</CCol>

			<div class="pl-3 name-container">
				<div class="typo-body-2" data-test-id="name">
					{{ name }}
				</div>
				<div>
					<span>Lowest Price Status: </span>
					<CBadge class="badge-status" :color="BANNER_STATUSES[isLowestPriceVisible].value">
						{{ BANNER_STATUSES[isLowestPriceVisible].name }}
					</CBadge>
				</div>
				<div
					v-if="type === PRODUCT_FAMILY_ITEM_TYPES.PRODUCT"
					class="typo-caption color-black-45 mb-2"
					data-test-id="sku"
				>
					{{ sku }}
				</div>
				<CBadge v-else class="badge-status badge-inactive">
					Category
				</CBadge>
			</div>
			<CCol md="2">
				<CButton
					color="secondary"
					class="btn btn-secondary px-4"
					@click="onClickAddBadgeLabel"
				>
					Edit
				</CButton>
			</CCol>
		</CCol>

		<!-- Modal Section -->
		<ModalMediaLibrary
			ref="media-library"
			type="single"
			@onSubmit="handleSubmitMedia"
		/>
	</CRow>
</template>

<script>
import ModalMediaLibrary from '@/components/ModalMediaLibrary.vue';
import { getThumbnailFromMediaObject } from '../assets/js/helpers';
import { IMAGE_PLACEHOLDER_URL } from '../enums/general';
import { PRODUCT_FAMILY_ITEM_TYPES } from '../enums/productFamilies';
import {
	BANNER_STATUSES,
} from '../enums/banners';

export default {
	name: 'ProductFamilyAttachItem',

	components: {
		ModalMediaLibrary,
	},

	props: {
		previewImage: {
			type: Object,
			default: null,
		},
		defaultImage: {
			type: String,
			default: IMAGE_PLACEHOLDER_URL,
		},
		name: {
			type: String,
			default: null,
		},
		sku: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: PRODUCT_FAMILY_ITEM_TYPES.PRODUCT,
		},
		isLowestPriceVisible: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			BANNER_STATUSES,
			PRODUCT_FAMILY_ITEM_TYPES,
			selectImage: this.previewImage,
		};
	},

	computed: {
		showPreviewImage() {
			return getThumbnailFromMediaObject(this.selectImage);
		},
	},
	methods: {
		handleClickPreviewImage() {
			this.$refs['media-library'].open();
		},
		handleDeletePreviewImage() {
			this.selectImage = null;
			this.$emit('onInputImage', null);
		},
		handleSubmitMedia(file = []) {
			const image = {
				...file[0],
				urls: file[0].imageUrls,
			};
			this.selectImage = image;
			this.$emit('onInputImage', image);
		},
		onClickAddBadgeLabel() {
			this.$emit('clickAddBadgeLabel');
		},
	},
};
</script>

<style lang="scss" scoped>
	$image-size: rem(70);

	.default-image {
		height: $image-size;
		width: $image-size;
	}

	.name-container {
		flex: 1;
	}

	.input-image::v-deep {
		.image-placeholder {
			height: $image-size;
			width: $image-size;

			img {
				height: rem(20);
				width: rem(20);
			}
		}

		.image-link {
			height: $image-size;
			width: $image-size;

			.image-preview {
				height: $image-size;
				width: $image-size;
			}
		}
	}

	.btn-width {
		width: 150px;
	}
</style>
